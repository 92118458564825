/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ( $ ) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {

				$( '#menu' ).mmenu( {
					'extensions': [
						'pagedim-black',
						'position-right',
						'theme-dark'
					]
				} );

				$.ajax( {
					url: ajax_init.ajax_url,
					type: 'POST',
					data: 'action=wc_cart',
					success: function ( data ) {
						$( '#cart-total-number' ).html( data ).appendTo( '.link-basket > a > i' ); // Move basket items to icon
					},
					error: function ( errorThrown ) {}
				} );

				$( '.reviews__slideshow' ).flickity( {
					// options
					contain: true,
					wrapAround: true,
					cellAlign: 'center',
					pageDots: false,
//					autoPlay: true,
//					adaptiveHeight: true
				} );

				$( '.sidebar--sticked' ).stick_in_parent( {
//					parent: '.main',
//					bottoming: false,
					offset_top: 290
				} );

				var resizeTimeout;

				var setWaiAria = function ( menuLi ) {
					menuLi.each( function ( i, el ) {
						var $el = $( el );
						$el.attr( 'aria-setsize', menuLi.length );
						$el.attr( 'aria-posinset', i + 1 );
					} );
				}

				// set wai aria aria-setsize and aria-posinset before cloning elements in other list
				setWaiAria( $( '#menu-hoofdmenu > li' ) );

				var resetMenu = function () {
					var parentWidth = $( '#menu-hoofdmenu' ).parent().width() - ($( '.navbar-brand' ).width() + 40);
					var ulWidth = $( '#more-nav' ).outerWidth();
					var menuLi = $( '#menu-hoofdmenu > li' );
					var liForMoving = new Array();
					var activeElement = $( document.activeElement )[0];

					// before remove item check if you have to reset the focus
					var removeOriginal = function ( item, clone ) {
						// check focused element
						if ( item.find( 'a' )[0] === activeElement ) {
							activeElement = clone.find( 'a' )[0];
						}

						item.remove();
					}

					//take all elements that can't fit parent width to array
					menuLi.each( function () {
						var $el = $( this );
						ulWidth += $el.outerWidth();
						if ( ulWidth > parentWidth ) {
							liForMoving.unshift( $el );
						}
					} );

					if ( liForMoving.length > 0 ) { //if have any in array -> move em to 'more' ul
						//e.preventDefault();

						liForMoving.forEach( function ( item ) {
							var clone = item.clone();
							clone.prependTo( '.subfilter' );

							removeOriginal( item, clone );
						} );

					} else if ( ulWidth < parentWidth ) { //check if we can put some 'li' back to menu
						liForMoving = new Array();

						var moved = $( '.subfilter > li' );
						for ( var i = 0, j = moved.length; i < j; i++ ) {
							var movedItem = $( moved[i] );

							var tmpLi = movedItem.clone();
							tmpLi.appendTo( $( '#menu-hoofdmenu' ) );

							ulWidth += movedItem.outerWidth();
							if ( ulWidth < parentWidth ) {
								removeOriginal( movedItem, tmpLi );
							} else {
								// dont move back
								ulWidth -= movedItem.outerWidth();
								tmpLi.remove();
							}

						}
					}
					if ( $( '.subfilter > li' ).length > 0 ) { //if we have elements in extended menu - and has focussed element then show it
						$( '#more-nav' ).show();

						if ( $( activeElement ).closest( '#more-nav' ).length ) {
							$( '#more-nav' ).addClass( 'active' );
						} else {
							$( '#more-nav' ).removeClass( 'active' );
						}
					} else {
						// check if 'more' link has focus then set focus to last item in list
						if ( $( '#more-nav' ).find( 'a' )[0] === $( document.activeElement )[0] ) {
							activeElement = $( '#menu-hoofdmenu > li:last-child a' )[0];
						}

						$( '#more-nav' ).hide();
						//$('#more-nav .subfilter').hide();
					}

					// reset focus
					activeElement.focus();
				}

				//we reconstruct menu on window.resize after delay
				$( window ).on( 'resize', function ( e ) {
					$( '#more-nav' ).hide();
					clearTimeout( resizeTimeout );
					if ( $( window ).width() > 993 ) {
						resizeTimeout = setTimeout( resetMenu, 100 );
					}
				} );

				// set clickhandler
				$( '.more' ).click( function () {
					$button = $( this );
					$button.focus();
					$( '#more-nav' ).toggleClass( 'active' );
				} );

				$( window ).trigger( 'resize' ); //call resize handler to build menu right

			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function ( func, funcname, args ) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if ( fire ) {
				namespace[func][funcname]( args );
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire( 'common' );

			// Fire page-specific init JS, and then finalize JS
			$.each( document.body.className.replace( /-/g, '_' ).split( /\s+/ ), function ( i, classnm ) {
				UTIL.fire( classnm );
				UTIL.fire( classnm, 'finalize' );
			} );

			// Fire common finalize JS
			UTIL.fire( 'common', 'finalize' );
		}
	};

	// Load Events
	$( document ).ready( UTIL.loadEvents );

})( jQuery ); // Fully reference jQuery after this point.